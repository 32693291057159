import React from 'react'
import Layout from '../components/layout/Layout'

const Page = ({pageContext}) => {
    return (
        <Layout
            locale={pageContext.locale}
            lang={pageContext.lang}
            alternatePath={pageContext.alternatePath}
            alternateLocale={pageContext.alternateLocale}
            hasFooter={false}
            showLogo={false}
            inverted={true}
        />
    )
}

export default Page
